<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-excape">
        <img src="@/assets/casestudy/excape-games-logo.png" alt="Excape Games">
        <span class="sep"></span>
        <p>EXCAPE GAMES is a live interactive game where a team of people are given a scenario and then locked in a room that they must escape. In order to win the game they need to escape within the
          60 minute time limit. The team must work together to search for clues and solve puzzles. Be ready for unexpected twist and turns. EXCAPE GAMES will keep your adrenaline pumping and your mind
          racing for one amazing hour full of mystery, clues and fun! Just when you think you have it figured out…you realize that nothing is as it seems.</p>
      </div>
    </div>

    <div class="laptop-img excape-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/excape-main-img.png" alt="Excape Games">
      </div>
    </div>

    <div class="container-about excape-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/excape-diamond.svg" alt="Excape Games">
        <h3>About the project</h3>
        <p>Two partners from Detroit and Clarksville decided to escape monotony and go into a wild and popular business of customer temporary entrapment.<br/>
          Their businesses flourished rapidly and they are thinking of franchising the idea so that the rest of the States benefit from their intricate ideas for rooms.<br/>
          We are responsible with their online presence, hosting and consultancy.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>
          <div class="font-typo">
            <img class="doublefont" src="@/assets/casestudy/chaparral-font.svg" alt="Vorbe Grele Main Font">
            <h3><span style="color:#12100c">Co</span><span style="color:#d52929">lo</span><span style="color:#eeb52b">rs</span></h3>

            <div class="colors">
              <div class="fcolor excape-p">Primary</div>
              <div class="scolor excape-s">Secondary</div>
              <div class="tcolor excape-t">Third</div>
            </div>

            <span class="sep vertical color-tag"></span>
            <div class="color-codes">
              <div class="ccodes">
                <p class="primary-ex">#d52929</p>
                <p class="secondary-ex">#eeb52b</p>
                <p class="third-ex">#12100c</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name:'Avigora'}" class="left">
              <img src="@/assets/casestudy/btn-avigora.jpg" alt="Avigora">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Careful'}" class="right">
              <img src="@/assets/casestudy/btn-careful.jpg" alt="Careful">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Home/Footer";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Excape",
  components: {LetsChat, Footer, ContactUs}
}
</script>

<style lang="scss">
.logo-excape img {
  max-width: 320px;
}

.excape-laptop {
  background-color: #eeb52b;
}

.excape-about {
  background: #1b1919;
}

.container-about h3 {
  color: #eeb52b;
}

.container-about p {
  color: #fafafa;
}

.excape-p {
  background-color: #d52929;
}

.primary-ex {
  color: #d52929;
}

.excape-s {
  background-color: #eeb52b;
}

.secondary-ex {
  color: #eeb52b;
}

.excape-t {
  background-color: #12100c;
}

.third-ex {
  color: #12100c;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 56%, #eeb52b 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>